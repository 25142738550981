type ShippingInfo = {
  name: string
  address: string
  city: string
  state: string
  zip: string
  country: string
}

export const useShoppingCart = () => {
  const cart = useState<Record<string, number>>('shopping-cart', () => ({}))

  const loadCart = () => {
    if (import.meta.client) {
      const stored = localStorage.getItem('shopping-cart')
      if (stored) {
        cart.value = JSON.parse(stored)
      }
    }
  }

  const saveCart = () => {
    if (import.meta.client) {
      localStorage.setItem('shopping-cart', JSON.stringify(cart.value))
    }
  }

  if (import.meta.client) {
    loadCart()
    watch(cart, saveCart, { deep: true })
  }

  const _getItemKey = (productId: number) => String(productId)

  const getItemQuantity = (productId: number) => {
    const key = _getItemKey(productId)
    return cart.value[key]
  }

  const isInCart = (productId: number) => {
    const key = _getItemKey(productId)
    return Object.prototype.hasOwnProperty.call(cart.value, key)
  }

  const addToCart = (productId: number, quantity: number) => {
    const key = _getItemKey(productId)
    cart.value = {
      ...cart.value,
      [key]: (cart.value[key] || 0) + quantity
    }
  }

  const checkOut = async (shippingInfo: ShippingInfo) => {
    const products = Object.entries(cart.value).map(
      ([productId, quantity]) => ({
        product_id: parseInt(productId),
        quantity
      })
    )

    try {
      await $fetch('/api/cart/checkout', {
        method: 'POST',
        body: {
          products,
          shipping: shippingInfo
        }
      })
      clearCart()
    } catch (error) {
      console.error('Error checking out', error)
      throw error
    }
  }

  const clearCart = () => {
    cart.value = {}
  }

  const removeFromCart = (productId: number) => {
    const key = _getItemKey(productId)
    const { [key]: _, ...rest } = cart.value
    cart.value = rest
  }

  const updateQuantity = (productId: number, newQuantity: number) => {
    const key = _getItemKey(productId)
    if (newQuantity > 0) {
      cart.value = { ...cart.value, [key]: newQuantity }
    } else {
      removeFromCart(productId)
    }
  }

  return {
    cart,
    getItemQuantity,
    isInCart,
    addToCart,
    checkOut,
    clearCart,
    removeFromCart,
    updateQuantity
  }
}
